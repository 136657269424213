import React, {useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import {makeStyles, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 8
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface IItem {
  id: string;
  art: string;
  material: string;
  size: string;
  price: number;
}

export default function RequestItem({index, items, onComplete}: {index: number, items: IItem[], onComplete: (index: number, itemId: string) => void}) {
  const classes = useStyles();

  const [art, setArt] = useState<string>('');
  const [itemsByArt, setItemsByArt] = useState<IItem[]>([]);
  const [materials, setMaterials] = useState<string[]>([]);
  const [value2, setMaterial] = useState<string>('');
  const [value3, setValue3] = useState<string>('');
  const [total, setTotal] = useState('0');
  const [deliveryTime, setDeliveryTime] = useState<boolean>(false);
  const [arts, setArts] = useState<string[]>([]);
  const [itemId, setItemId] = useState<string>('');

  useEffect(() => {
    const arts = Array.from(new Set(items.map(item => item.art))).sort();
    setArts(arts);
  }, []);

  const handleChangeArt = (event: React.ChangeEvent<{ value: unknown }>) => {
    const art = event.target.value as string;
    setArt(art);
    const currentItems = items.filter(item => item.art === art);
    setItemsByArt(currentItems);
    const materials = Array.from(new Set(currentItems.map(item => item.material)));
    setMaterials(materials);
    setMaterial('');
    setValue3('');
    setTotal('0');
    setDeliveryTime(false);
  };

  const handleChangeMaterial = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaterial(event.target.value as string);
    setValue3('');
    setTotal('0');
    setDeliveryTime(false);
  };

  const handleChangeSize = (event: React.ChangeEvent<{ value: unknown }>) => {
    const itemId = event.target.value as string;
    setValue3(itemId);
    const item = items.find(item => item.id === itemId);
    let total = item?.price + '' || '0';
    if (total === 'request') {
      total = 'под запрос';
    }
    setTotal(total);
    setDeliveryTime(true);
    setItemId(itemId);

    onComplete(index, itemId);
  };

  const renderDeliveryTime = () => {
    if (!deliveryTime) {
      return <div>n/a</div>
    }

    return <div>1 &ndash; 1,5 мес.</div>
  };

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {/* End hero unit */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={2} style={{overflow: 'hidden'}}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Артикул</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={art}
              onChange={handleChangeArt}
            >
              {
                arts.map(art => <MenuItem key={art} value={art}>{art}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label-2">Материал</InputLabel>
            <Select
              labelId="demo-simple-select-label-2"
              id="demo-simple-select-2"
              value={value2}
              onChange={handleChangeMaterial}
            >
              {
                materials.map(material => <MenuItem key={material} value={material}>{material}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label-3">Размер</InputLabel>
            <Select
              labelId="demo-simple-select-label-3"
              id="demo-simple-select-3"
              value={value3}
              onChange={handleChangeSize}
            >
              {
                itemsByArt.filter(item => item.material === value2).map(item => <MenuItem key={item.id} value={item.id}>{item.size}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl className={classes.formControl}>
          <div>Цена (руб):</div><br/>
          <div>{total}</div>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl className={classes.formControl}>
            <div>Срок поставки:</div><br/>
            {renderDeliveryTime()}
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  )
}
