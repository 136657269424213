import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import RequestItem from "./Components/RequestItem";

import * as superagent from 'superagent';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface IItem {
  id: string;
  art: string;
  material: string;
  size: string;
  price: number;
}

// const SERVER_ADDRESS = 'http://localhost:3001/';
const SERVER_ADDRESS = 'https://niob-prices.site/';

export default function App() {
  const classes = useStyles();
  const [items, setItems] = useState<IItem[]>([]);
  const [ids, setIds] = useState<string[]>(['']);

  useEffect(() => {
    async function fetchData() {
      // const {body: data} = await superagent.get(SERVER_ADDRESS);
      const {body: data} = await superagent.get(`${SERVER_ADDRESS}items?r=${Date.now()}`);
      const {items} = data;
      setItems(items);
    }

    fetchData();
  }, []);


  const printPDF = () => {
    // @ts-ignore
    window.location = `${SERVER_ADDRESS}pdf?ids=${ids.join(',')}`;
  };

  const addPosition = () => {
    const newIds = [...ids];
    newIds.push('');
    setIds(newIds);
  };

  const handleRequestItemComplete = (index: number, itemId: string) => {
    const newIds = [...ids];
    newIds[index] = itemId;
    setIds(newIds);
  };

  const renderRequestItems = () => {
    if (items.length === 0) {
      return null;
    }

    return ids.map((_, index) => <RequestItem key={index} index={index} items={items} onComplete={handleRequestItemComplete}/>)
  };

  return (
    <React.Fragment>
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              <p>Здесь вы можете посмотреть цены продажи и срок поставки продукции Niob Fliuid</p>
            </Typography>
            {renderRequestItems()}
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={printPDF} disabled={ids[0] === ''}>
                    Сохранить PDF
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={addPosition}>
                    Добавить позицию
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Container className={classes.cardGrid} maxWidth="md">
              <Typography variant="h6" align="center" color="textSecondary" paragraph>
                <p>Артикулы указаны в соответствии с каталогами:</p>
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/02_spojovaci_armatury_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/02_spojovaci_armatury_cz_ru_1_001v2.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/03_potrubni_tvarovky_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/03_potrubni_tvarovky_cz_ru_001.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/04_regulacni_armatury_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/04_regulacni_armatury_cz_ru(1)_001.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/05_specialni_armatury_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/05_specialni_armatury_cz_ru_001.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/06_vlezy_a_prulezy_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/06_vlezy_a_prulezy_cz_ru_001.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/07_prislusenstvi_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/07_prislusenstvi_cz_ru_001.jpg'} />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <a href="http://www.niobfluid.cz/katalogy_2021/08_dvouplastove_armatury_cz_ru.pdf" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/img/08_dvouplastove_armatury_cz_ru_001.jpg'} />
                  </a>
                </Grid>
                </Grid>
            </Container>
            <Typography align="center" color="textSecondary" paragraph>
              <a href={process.env.PUBLIC_URL + '/Dop info.docx'} target="_blank">Дополнительная информация по товарам</a>
            </Typography>
            <Typography align="center" color="textSecondary" paragraph>
              <Link href="mailto:techotdel@inoxtrade.ru?subject=вопрос по Niob Fluid" underline="none">
                <Button variant="contained" color="primary">
                  Задать вопрос специалисту
                </Button>
              </Link>
            </Typography>
          </Container>
        </div>
      </main>
      <footer className={classes.footer}>
        <Typography align="center" color="textSecondary" paragraph>
          <p>Данные цены не распространяются на продукцию, находящуюся на складе Инокстрейд.</p>
        </Typography>
      </footer>
    </React.Fragment>
  );
}
